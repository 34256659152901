<!-- Зведення проекту -->

<template>

    <!-- Підсумок по завданням -->
    <div class="row" style="margin-top: 15px;">

        <!-- Усього завдань -->
        <div class="col-xl-3 col-sm-12">
            <div class="card card-animate" style="border-radius: 8px;">
                <div class="card-body bg-" id="infoStep0" style="border-radius: 8px;">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <p class="text-uppercase fw-medium  mb-0"><b>{{ $t('Total') }}</b></p>
                        </div>
                    </div>
                    <div class="d-flex align-items-end justify-content-between mt-3">
                        <div>
                            <h4 class="fs-22 fw-semibold ff-secondary">
                                <span class="counter-value">{{ form.totalTasks.total }}</span>
                            </h4>
                        </div>
                        <div class="avatar-sm flex-shrink-0">
                            <span class="avatar-title bg-soft-success text-success rounded-circle fs-4">
                                <i class="las la-file-invoice"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Кількість нових завдань -->
        <div class="col-xl-3 col-sm-12">
            <div class="card card-animate" style="border-radius: 8px;">
                <div class="card-body bg-" id="infoStep1" style="border-radius: 8px;">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <p class="text-uppercase fw-medium  mb-0"><b>{{ $t('new') }}</b></p>
                        </div>
                    </div>
                    <div class="d-flex align-items-end justify-content-between mt-3">
                        <div>
                            <h4 class="fs-22 fw-semibold ff-secondary">
                                <span class="counter-value">{{ form.totalTasks.new }}</span>
                            </h4>
                        </div>
                        <div class="avatar-sm flex-shrink-0">
                            <span class="avatar-title bg-soft-success text-success rounded-circle fs-4">
                                <i class="las la-file-invoice"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Кількість завдань в роботі -->
        <div class="col-xl-3 col-sm-12">
            <div class="card card-animate" style="border-radius: 8px;">
                <div class="card-body bg-" id="infoStep2" style="border-radius: 8px;">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <p class="text-uppercase fw-medium  mb-0"><b>{{ $t('statusInWork') }}</b></p>
                        </div>
                    </div>
                    <div class="d-flex align-items-end justify-content-between mt-3">
                        <div>
                            <h4 class="fs-22 fw-semibold ff-secondary">
                                <span class="counter-value">{{ form.totalTasks.inJob }}</span>
                            </h4>
                        </div>
                        <div class="avatar-sm flex-shrink-0">
                            <span class="avatar-title bg-soft-danger text-danger rounded-circle fs-4">
                                <i class="las la-file-invoice"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Кількість завершених завдань -->
        <div class="col-xl-3 col-sm-12">
            <div class="card card-animate" style="border-radius: 8px;">
                <div class="card-body bg-" id="infoStep3" style="border-radius: 8px;">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <p class="text-uppercase fw-medium  mb-0"><b>{{ $t('registrationCompleted') }}</b></p>
                        </div>
                    </div>
                    <div class="d-flex align-items-end justify-content-between mt-3">
                        <div>
                            <h4 class="fs-22 fw-semibold ff-secondary">
                                <span class="counter-value">{{ form.totalTasks.completed }}</span>
                            </h4>
                        </div>
                        <div class="avatar-sm flex-shrink-0">
                            <span class="avatar-title bg-soft-info text-info rounded-circle fs-4">
                                <i class="las la-file-invoice"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <b-row>

        <!-- Зведення по статусам -->
        <b-col lg="4">
            <b-card>
                <div class="card-body" >
                    <h5 class="card-title mb-1 fw-semibold">{{ $t('Зведення по статусам') }}</h5>
                    <p class="card-text">{{ $t('Отримайте уявлення про стан ваших завдань') }}</p>
                    <div class="cdc-container_flex mt-4">
                        <vc-donut
                            :size="200"
                            unit="px"
                            :thickness="35"
                            :background="`${statusTheme == 'dark' ? '#212529' : 'white'}`"
                            foreground="#eeeeee"
                            :sections="highlightedSections"
                            @section-mouseenter="setHovered"
                            @section-mouseleave="resetHovered"
                        >
                            <template v-if="hoveredValue != null">
                                <h2>{{ hoveredValue }}%</h2>
                                {{ hoveredLabel }}
                            </template>
                            <!-- <template v-else>
                                <h2>{{ sections[sections.length-1].value }}%</h2>
                                {{ sections[sections.length-1].label }}
                            </template> -->
                        </vc-donut>
                        <div class="cdc-legend w-100" style="flex-direction: column; margin: 0px 0px 0px 1em;">
                            <span 
                                v-for="section in sections" 
                                :key="section.label" 
                                class="cdc-legend-item-section pe-2" 
                                @mouseover="setHovered(section)"
                                @mouseleave="resetHovered"
                            >
                                <div class="d-flex w-100" style="align-items: center;">
                                    <div class="flex-grow-1 ms-2">
                                        <span class="cdc-legend-item">
                                            <span class="cdc-legend-item-color" :style="`background-color: ${section.color};`"></span>
                                            <span class="fw-semibold fs-13">{{ section.label }}</span>
                                        </span>
                                    </div>
                                    <div class="flex-shrink-0 text-end">
                                        <h6 style="margin: 0;">{{ section.count }} <span class="text-muted fs-11">{{ section.value }}%</span></h6> 
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-col>

         <!-- Розподіл пріоритетів -->
         <b-col lg="4">
            <b-card>
                <div class="card-body" >
                    <h5 class="card-title mb-1 fw-semibold">{{ $t('Розподіл пріоритетів') }}</h5>
                    <p class="card-text mb-3">{{ $t('Отримайте цілісне уявлення про розміщення пріоритетів завдань') }}</p>
                    <apexchart 
                        class="apex-charts mt-4" 
                        height="200" dir="ltr" 
                        :series="distributedColumnchart.series"
                        :options="distributedColumnchart.chartOptions"
                    >
                    </apexchart>
                </div>
            </b-card>
        </b-col>

        <!-- Навантаження по команді -->
        <b-col lg="4">
            <b-card>
                <div class="card-body" >
                    <h5 class="card-title mb-1 fw-semibold">{{ $t('Load_on_command') }}</h5>
                    <p class="card-text">{{ $t('Load_on_command_desc') }}</p>
                    <div style="height: 200px;">
                        <table class="table table-nowrap mt-4 mb-0">
                            <thead>
                                <tr>
                                    <th scope="col" style="width:30%;">{{ $t('Performer') }}</th>
                                    <th scope="col">{{ $t('Work_distribution') }}</th>
                                    <th scope="col">{{ $t('Quantity') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="item in form.teamReport" :key="item" >
                                    <tr>
                                        <td>{{ item.performerWorkerName ? item.performerWorkerName : $t('NotDefined') }}</td>
                                        <td>
                                            <div class="progress animated-progress">
                                                <div :class="`progress-bar bg-${item.name == 'Не назначено' ? 'primary' : 'info'}`" role="progressbar" :style="`width: ${item.progressPercent}%`" :aria-valuenow="item.progressPercent" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </td>
                                        <td>{{ item.count ? item.count : 0 }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card>
        </b-col>

        <!-- Нещодавня активність -->
        <b-col lg="6">
            <b-card>
                <div class="card-body" >
                    
                    <div class="d-flex mb-3" style="align-items: center">
                        <div class="flex-grow-1">
                            <h5 class="card-title mb-1 fw-semibold">{{ $t('recent_activity') }}</h5>
                            <p class="card-text">{{ $t('recent_activity_desc') }}</p>
                        </div>
                        <div class="flex-shrink-0">
                            <div class="dropdown card-header-dropdown">
                                <b-link 
                                    class="text-reset dropdown-btn" 
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true" 
                                    aria-expanded="false"
                                >
                                    <span class="fw-medium fs-12">
                                        {{ $t('On_assignment') }}
                                        <i class="mdi mdi-chevron-down ms-1"></i>
                                    </span>
                                </b-link>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <b-link class="dropdown-item" >{{ $t('On_assignment') }}</b-link>
                                    <b-link class="dropdown-item">{{ $t('By_project') }}</b-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div style="height: 400px;overflow: auto;">
                        <template v-for="item in recent_activity" :key="item">

                            <div class=" " >
                                <span class="badge text-muted bg-light mb-3 fs-12">{{ item.date }}</span>
                                
                                <template v-for="i in item.items" :key="i">
                                    
                                    <div class="d-flex mb-2" >
                                        <div class="flex-shrink-0" >
                                            <div class="avatar-xs">
                                                <div class="avatar-title bg-info rounded-circle">
                                                    {{ this.getInitials(i.user) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-grow-1 ms-3" >
                                            <h5 class="fs-13">{{ i.user }} <small class="text-muted ms-2">{{ i.time }}</small></h5>
                                            <p class="">{{ i.action }} <b>{{ i.task }}</b></p>
                                            <div v-if="i.comment" class="alert bg-light border-light text-body" role="alert">
                                                {{ i.comment }}
                                            </div>
                                        </div>
                                    </div>

                                </template>

                            </div>
                        </template>
                    </div>

                </div>
            </b-card>

        </b-col>

    </b-row>

</template>

<script>
import { developmentProjects } from '@/API.js';
import { storeS } from '@/store' // глобальне сховище даних

let apiServe = new developmentProjects();

export default {
    props: ["objCard"],
    data() {
        return {
            hoveredLabel: 'Hover over a section',
            hoveredValue: null,
            activeSection: null,
            distributedColumnchart: {
                series: [{
                    name: this.$t('Quantity'),
                    data: [0, 0, 0, 0],
                }, ],
                chartOptions: {
                    chart: {
                        height: 250,
                        type: "bar",
                        toolbar: {
                            show: false
                        },
                        events: {
                            click: function () {
                                // console.log(chart, w, e)
                            },
                        },
                    },
                    colors: ["#09b39c", "#f7b84a", "#f06447", "#f06447"],
                    plotOptions: {
                        bar: {
                            columnWidth: "30%",
                            distributed: true,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    legend: {
                        show: false,
                    },
                    xaxis: {
                        categories: [
                            this.$t('priorityLow'), 
                            this.$t('priorityMedium'), 
                            this.$t('priorityHigh'), 
                            this.$t('Critical')
                        ],
                        labels: {
                            style: {
                                colors: ['#09b39c', '#f7b84a', '#f06447', '#f06447'],
                                fontSize: '12px'
                            },
                        }
                    },
                }
            },
            sections: [
                { label: this.$t('TobePerformed'), value: 1, count: 0, color: '#eeeeee' }
            ],
            form: {
                totalTasks: {
                    new: '',
                    total: '',
                    inJob: '',
                    completed: ''
                }
            },
            
            recent_activity: [
                {
                    "date": "Сьогодні",
                    "items": [
                    {
                        "user": "Danya Latiy",
                        "action": "прокомментировал(а)",
                        "task": "PLUS-7 - Не коректне відображення кнопки видалення в картці Бонусної програми",
                        "time": "9:30",
                        "comment": "Я так зрозумів ви тут змінили логіку і тепер для видалення треба замінити бонусну програму. Якщо це так тоді все працює ок. Зараз ще поклацаю як воно точно працює. Якщо щось знайду то сюди відпишу."
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "action": "прокомментировал(а)",
                        "task": "PLUS-5 - Додати можливість обирати групу клієнтів для окремо обраних клієнтів через меню Дія",
                        "time": "7:39",
                        "comment": "Сорян, май бед, я просто недозагрузив 1 файл, зараз загружу і все буде воркати."
                    }
                    ]
                },
                {
                    "date": "Вчора",
                    "items": [
                    {
                        "user": "Danya Latiy",
                        "action": "прокомментировал(а)",
                        "task": "PLUS-13 - Неможливо перевибрати знижку у вікні групи клієнтів",
                        "time": "17:00",
                        "comment": "Vadim Atamanyuk я думаю що краще зробить можливість перевибору бо може клієнт випадково її прибере і потім захоче ту ж саму знижку вибрати а її немає."
                    },
                    {
                        "user": "Danya Latiy",
                        "action": "прокомментировал(а)",
                        "task": "PLUS-15 - Не коректно відображається альорт про не вибрану програму лояльності в модальному вікні групи клієнтів(ios)",
                        "time": "16:20",
                        "comment": "Vadim Atamanyuk Угу і типу не дуже воно гарно виглядає."
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "action": "прокомментировал(а)",
                        "task": "PLUS-15 - Не коректно відображається альорт про не вибрану програму лояльності в модальному вікні групи клієнтів(ios)",
                        "time": "14:20",
                        "comment": "Проблема в тому що повідомлення налазить на кнопки?"
                    }
                    ]
                },
                {
                    "date": "понедельник, 25 ноября 2024 г.",
                    "items": [
                    {
                        "user": "Vadim Atamanyuk",
                        "action": "изменил(а) Исполнитель на",
                        "executor": "Vadim Atamanyuk",
                        "task": "PLUS-13 - Неможливо перевибрати знижку у вікні групи клієнтів",
                        "time": "18:00"
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "action": "изменил(а) статус на",
                        "status": "В работе",
                        "task": "PLUS-13 - Неможливо перевибрати знижку у вікні групи клієнтів",
                        "time": "17:32"
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "action": "обновил(а) Rank из",
                        "task": "PLUS-13 - Неможливо перевибрати знижку у вікні групи клієнтів",
                        "time": "11:50"
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "action": "прокомментировал(а)",
                        "task": "PLUS-13 - Неможливо перевибрати знижку у вікні групи клієнтів",
                        "time": "9:10",
                        "comment": "Vadim Atamanyuk я думаю що краще зробить можливість перевибору бо може клієнт випадково її прибере і потім захоче ту ж саму знижку вибрати а її немає."
                    }
                    ]
                }
            ],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            // Отримуємо дані по зведенню

            apiServe.reduction(this.objCard.projectId).then(result => {
                if(result.status == 'done') {
                    this.form = result.data

                    // Відсортувати за пріоритетом
                    const sortedReport = result.data.priorityReport.sort((a, b) => a.priority - b.priority);
                    this.distributedColumnchart.series[0].data = sortedReport.map(item => item.count);

                    this.sections = result.data.statusReport.map(item => ({
                        label: item.columnName,
                        value: parseFloat(item.percentTasks),
                        count: item.countTasks,
                        color: item.color || '#cccccc' // Колір за замовчуванням, якщо немає в colorMap
                    }));

                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання зведення проєкту'; 
                    var statusRequest = result.status;
                    var api = 'reduction';
                    var fileRequest = 'src/views/development/projects/project/tabs/summary/index.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        setHovered(section) {
            console.log("section", section);
            this.hoveredLabel = section.label;
            this.hoveredValue = section.value;
            this.activeSection = section;
        },
        resetHovered() {
            this.hoveredLabel = 'Hover over a section';
            this.hoveredValue = null;
            this.activeSection = null;
        },
        darkenColor(color, amount) {
            const colorValue = color.replace('#', '');
            const num = parseInt(colorValue, 16);
            let r = (num >> 16) - amount * 255;
            let g = ((num >> 8) & 0x00ff) - amount * 255;
            let b = (num & 0x0000ff) - amount * 255;
            r = r < 0 ? 0 : r > 255 ? 255 : r;
            g = g < 0 ? 0 : g > 255 ? 255 : g;
            b = b < 0 ? 0 : b > 255 ? 255 : b;
            return `rgb(${r}, ${g}, ${b})`;
        },
    },
    computed: {
        highlightedSections() {
            return this.sections.map((section) => ({
                ...section,
                color:
                section === this.activeSection
                    ? this.darkenColor(section.color, 0.2)
                    : section.color,
            }));
        },
        statusTheme(){
            // Отримання статусу теми
            return storeS.nightTheme
        }
    },
}
</script>

<style scoped>
.cdc-container_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.legend {
  list-style-type: none;
  padding: 0;
}
.legend li {
  margin: 5px 0;
  font-size: 14px;
}
span.cdc-legend-item-section:hover {
    background: #d7e3ed54;
    border-radius: 5px;
    transition: .7s;
}
</style>