<!-- тікети -->

<template>   
    <!-- 
      Компонент таблиці 
      
    -->
    <tablecustom
        :objParams='objParams'
        :dataParams='dataParams'
        :rows='rows'
        :columns='columns'
        :createdform="true" 
        :showpag="true"
        @create="create"
        @open="open"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index.vue'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import { Notion } from '@/API';

let apiServe = new Notion();

export default {
    props: ["objCard", "type"],
    components: {
        tablecustom
    },
    data() {
        return {
            form: '',
            showpag: false,
            dataParams: {
                status: false,
                page: "notion"
            },
            objParams: {
                page: '',
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "nomenclatureId",
                    align: "left",
                    status: true,
                },
            ],
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(page){
            /*
                Функція для отримання даних для таблиці.
                Викликає API для отримання списку тікетів з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows (список тікетів) та objPages (інформація про сторінки).
            */

            apiServe.getDocs(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.objPages = result.data;
                    this.rows = result.data.items;  
                } else {
                    this.$toast.error(this.$t('error') + ` #300`);
                }
            }).catch(err => {
                this.$toast.error(this.$t('error') + ` # 300`);
            })
        },
        open: function(e){
             /**
                Функція для відкриття модального вікна з інформацією про тікет за його ідентифікатором.
                * @param {Object|String} e - Ідентифікатор тікету або об'єкт з інформацією про тікет.

                Викликає API для отримання інформації про тікет.
                Результат присвоюється властивості objcard, та встановлюється прапорці showModal в true для відображення модального вікна.

                Для відображення стрілочок перемикання вперед та назад - 
                * ifPrevopen - перевіряється, чи є ще картка тікету, до поточної відкритої картки
                * ifNextopen - перевіряється, чи є ще картка тікету, після поточної відкритої картки
            */

            // Визначення ідентифікатора тікету.
            var id = e.ticketId ? e.ticketId : e;

            // Перевірка, чи тікет є першим у списку для відкриття вікна "Попередній тікет".
            this.ifPrevopen = id == this.rows[0].ticketId ? false : true;

            // Перевірка, чи тікет є останнім у списку для відкриття вікна "Наступний тікет".
            this.ifNextopen = id == this.rows[this.rows.length-1].ticketId ? false : true;
            
            apiServe.getTicket(id).then(result => {
                if(result.status == 'done'){

                    if(!Array.isArray(result.data.userPhones)) {
                        this.$toast.error(this.$t('error'));

                        var errorText = 'Помилка відкриття тікету. Номери string'; 
                        var statusRequest = "error";
                        var api = 'getTicket';
                        var fileRequest = 'src/views/newtickets/all.vue';
                        var params = id;
                        var response = result.data.userPhones

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }

                    this.form = result.data;
                    this.showModal = true;
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка відкриття тікету'; 
                    var statusRequest = result.status;
                    var api = 'getTicket';
                    var fileRequest = 'src/views/newtickets/all.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за ...
            */

            this.objParams.search = e
            this.getdata();
        },
        // змінити ліміт і оновити дані
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        create() {
            window.location.pathname = "/notion/create"
        },
    },
    mounted() {
        
        // якщо існують таби в сторі, то дістаємо їх 
        this.tableTabs = localStorage.getItem("devProject" + this.objCard.projectName + "Problem") ? JSON.parse(localStorage.getItem("devProject" + this.objCard.projectName + "Problem")) : [];
    },
    computed: {
        perms(){
            return storeS.perms
        },
        workers() {
            return storeS.workers
        },
        checks() {
            return storeS.checks
        },
        user(){
            return storeS.userbase
        }
    },
}
</script>